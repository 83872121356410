<template>
    <div>
      <h2>Upload Files</h2>
      <input type="file" @change="onFileChange" multiple />
      <button @click="uploadFiles">Upload</button>
    </div>
  </template>
  
  <script>
  import { uploadData } from 'aws-amplify/storage';
  import { getCurrentUser } from 'aws-amplify/auth';

  export default {
    data() {
      return {
        files: [],
      };
    },
    methods: {
      onFileChange(e) {
        this.files = e.target.files;
      },
      async uploadFiles(event) {
        const user = await getCurrentUser();
        console.log("User", user);
        for (let file of this.files) {
          const fileName = `files/${user.userId}/${file.name}`;
          console.log("Uploading file:", fileName);
          await uploadData({
            path: fileName, 
            data: event.target.result,
            options: {
                bucket: 'userContent',
            }
          });
        }
      },
    },
  };
  </script>
  