<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <authenticator>
    <template v-slot="{ signOut, user }">
      Hello {{ user.username }}
      <HelloWorld msg="Welcome to Your Vue.js App"/>
      <button @click="signOut">Sign Out</button>
      <FileUpload />
    </template>
  </authenticator>
</template>

<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

import HelloWorld from './components/HelloWorld.vue'
import FileUpload from './components/FileUpload.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Authenticator,
    FileUpload,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
